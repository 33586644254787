@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Oswald:wght@200;300;400;500;600;700&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

.header-link{
    @apply font-bold font-press-start text-[16px] uppercase cursor-pointer transition-all duration-300 text-[#0e264f] hover:text-[#3660d9]
}
.btn{
    border-radius: 4px;
    background-image: -webkit-linear-gradient( 0deg, rgb(54,96,217) 0%, rgb(9,152,230) 100%);
    box-shadow: 0px 10px 30px 0px rgb(54 96 217 / 30%);
    border: none;
    color: #fff;
    transition:all .5s linear;
    padding: 0 30px;
    height:4.4rem
}
.btn:hover{
    background-image: -webkit-linear-gradient( 0deg, rgb(9,152,230)  0%, rgb(54,96,217) 100%);
}
.btn2{
    border-radius: 4px;
    background-image: -webkit-linear-gradient( 0deg, rgb(54,96,217) 0%, rgb(9,152,230) 100%);
    box-shadow: 0px 10px 30px 0px rgb(54 96 217 / 30%);
    border: none;
    color: #fff;
    transition:all .5s linear;
    padding: 0 30px;
    height:3.5rem
}
.btn2:hover{
    background-image: -webkit-linear-gradient( 0deg, rgb(9,152,230)  0%, rgb(54,96,217) 100%);
}
.slick-list{
    overflow:vissible;
}

label:after {
  content: '+';
  position: absolute;
  right: 1em;
  color: #fff;
}

input:checked + label:after {
  content: '-';
  line-height: .8em;
}

.accordion__content{
  max-height: 0em;
  transition: all 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87);
}
input[name='panel']:checked ~ .accordion__content {
  /* Get this as close to what height you expect */
  max-height: 50em;
}

.Label__BTN{
    height:61px !important;
}
.Label__BTN::after{
    background-image: -webkit-linear-gradient( 0deg, rgb(9,152,230) 0%, rgb(54,96,217) 100%);
    height:100%;
    top:0;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:26px;
    width:61px;
    height:100% !important;
    right:0;
    content: '<';
}
input:checked + .Label__BTN:after{
    content: '>';
    transform:rotate(90deg)

}